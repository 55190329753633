import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['downloader']

  getEnquiriesSchedule (e) {
    e.preventDefault();
    let selectedEnquiries = document.querySelectorAll('input[data-target="enquiry"]:checked')
    console.log(e.currentTarget)
    if (selectedEnquiries.length < 1) {
      alert('Please select enquiries')
      return true
    }
    let enquiry_ids = []
    let url = this.downloaderTarget.href
    for (const enquiryEl of selectedEnquiries) {
      enquiry_ids.push(`enquiry_ids[]=${enquiryEl.value}`)
    }
    this.downloaderTarget.setAttribute('href', `${url}?${enquiry_ids.join("&")}`)
    console.log(this.downloaderTarget)
    this.downloaderTarget.click()
    for (const enquiryEl of selectedEnquiries) enquiryEl.checked = false
  }
}
