import { Controller } from 'stimulus'
import $ from 'jquery'
import 'select2'

export default class extends Controller {
  connect() {
    let width = this.element.dataset.width || 'resolve'
    $(this.element).select2({ width: width, placeholder: this.element.getAttribute('placeholder') })
  }
}
