import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parent', 'suites']

  setUrl (e) {
    if (this.currentValue === '') {
      e.preventDefault()
      return false
    }

    this.parentTarget.dataset.url = this.currentProperty.dataset.url
  }

  populateSelect (e) {
    const [data] = e.detail
    this.options = data
  }

  set options (groups) {
    this.suitesTarget.innerHTML = ''
    let floors = Object.keys(groups)
    for (const floor of floors) {
      let optgroup = document.createElement('OPTGROUP')
      optgroup.label = floor
      this.suitesTarget.add(optgroup)
      let options = groups[floor]
      for (const option of options) {
        optgroup.appendChild(new Option(option.name, option.id))
      }
    }
  }

  get currentProperty () {
    return this.parentTarget.options[this.parentTarget.selectedIndex]
  }

  get currentValue () {
    return this.parentTarget.value
  }
}
