import { Controller } from 'stimulus'
import Modal from 'bootstrap.native/dist/components/modal-native.esm'

export default class extends Controller {
  static targets = ['trigger']

  render(e) {
    const [data, status, xhr] = e.detail
    let modal = data.querySelector('.modal')
    document.body.appendChild(modal)
    let instance = new Modal(modal)
    instance.show()
  }
}
