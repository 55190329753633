import Trix from 'trix'

Trix.config.textAttributes.underline = {
  tagName: 'u',
  inheritable: true,
  parser: (element) => {
    const style = window.getComputedStyle(element)
    return style.textDecoration === 'underline'
  }
}
