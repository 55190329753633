import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parent', 'contacts', 'locations']

  setUrl (e) {
    if (this.currentValue === '') {
      e.preventDefault()
      return false
    }

    this.parentTarget.dataset.url = this.currentCompany.dataset.url
  }

  populateSelect (e) {
    const [data] = e.detail
    this.locations = data.locations
    this.contacts = data.contacts
  }

  set locations (options) {
    this.addOptions(options, this.locationsTarget)
    // this.locationsTarget.innerHTML = ''
    // this.locationsTarget.add(new Option())
    // for (const option of options) {
    //   this.locationsTarget.add(new Option(option.name, option.id))
    // }
  }

  set contacts (options) {
    this.addOptions(options, this.contactsTarget)
    // this.contactsTarget.innerHTML = ''
    // this.contactsTarget.add(new Option())
    // for (const option of options) {
    //   this.contactsTarget.add(new Option(option.name, option.id))
    // }
  }

  addOptions (options, target) {
    target.innerHTML = ''
    target.add(new Option())
    for (const option of options) {
      target.add(new Option(option.name, option.id))
    }
  }

  get currentCompany () {
    return this.parentTarget.options[this.parentTarget.selectedIndex]
  }

  get currentValue () {
    return this.parentTarget.value
  }
}
