import { Controller } from 'stimulus'
import cleanArray from 'clean-array'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['postcode', 'number', 'list', 'line1', 'line2', 'line3', 'line4', 'city', 'county', 'latitude', 'longitude', 'fields', 'listContainer']

  connect () {
    this.listContainerTarget.hidden = true
    if (this.postcodeTarget.value === '') {
      this.fieldsTarget.hidden = true
    }
  }

  setup (e) {
    if (!this.postcodeTarget.value.length) e.preventDefault()

    this.postcodeTarget.value = this.postcodeTarget.value.toUpperCase()

    e.currentTarget.dataset.params = $.param({
      postcode: this.postcodeTarget.value,
      number: this.numberTarget.value
    })
  }

  update (e) {
    const [data] = e.detail
    this.latLng = data
    if (data.addresses.length > 1) {
      this.list = data.addresses
      this.listContainerTarget.hidden = false
      this.fieldsTarget.hidden = true
    } else {
      this.property = data.addresses[0]
      this.listContainerTarget.hidden = true
      this.fieldsTarget.hidden = false
    }
  }

  select (e) {
    const option = e.currentTarget.options[e.currentTarget.selectedIndex]
    this.property = JSON.parse(option.dataset.address)
    this.fieldsTarget.hidden = false
  }

  _textAddress (address) {
    return cleanArray(address).join(', ')
  }

  set list (list) {
    this.listTarget.innerHTML = ''
    this.listTarget.add(new Option('Select Address', ''))
    for (const property of list) {
      let option = new Option(this._textAddress(property), this._textAddress(property))
      option.dataset.address = JSON.stringify(property)
      this.listTarget.add(option)
    }
  }

  set property (property) {
    let [line1, line2, line3, line4AndCity, county] = property

    let [city, line4] = line4AndCity.split(',').slice(-2).reverse()

    if (line4) line4 = line4.trim()
    else line4 = ''

    this.line1Target.value = line1.trim()
    this.line2Target.value = line2.trim()
    this.line3Target.value = line3.trim()
    this.line4Target.value = line4
    this.cityTarget.value = city.trim()
    this.countyTarget.value = county.trim()
  }

  set latLng (data) {
    this.latitudeTarget.value = data.latitude
    this.longitudeTarget.value = data.longitude
  }
}
