import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['address', 'latitude', 'longitude']

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)

    let handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.autocomplete.addListener('place_changed', handlePlaceChanged)
    google.maps.event.addDomListener(this.addressTarget, 'keydown', function(e) {
      if (e.key == 'Enter') {
        e.preventDefault()
      }
    })
  }

  handlePlaceChanged(e) {
    let place = this.autocomplete.getPlace()
    if (!place.geometry) return false

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }
}
