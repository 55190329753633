import { Controller } from 'stimulus'
import $ from 'jquery'
import BSN from 'bootstrap.native/dist/bootstrap-native.esm'
import Modal from 'bootstrap.native/dist/components/modal-native.esm'

export default class extends Controller {
  static targets = ['response', 'form', 'selectLoader', 'loadableSelect']

  connect() {
    this.initDropdownAjaxLoader()
  }

  initDropdownAjaxLoader() {
    let selectloadertarget = this.targets.find('selectLoader');
    let loadableselecttarget = this.targets.find('loadableSelect');
    if (selectloadertarget && loadableselecttarget)
      $(this.selectLoaderTarget).on('ajax:success', this.populateSelect.bind(this));
  }

  populateSelect(e) {
    $(this.loadableSelectTarget).find('option').remove().end();
    const [data, status, xhr] = e.detail
    var options = '';
    for (let i = 0; i < data.length; i++) {
      options += '<option value="' + data[i].id + '">' + data[i].first_name + ' ' + data[i].last_name + '</option>';
    }
    $(this.loadableSelectTarget).append(options);
  }

  renderErrors(e) {
    const [data, status, xhr] = e.detail
    e.currentTarget.outerHTML = xhr.response
    let event = document.createEvent('Event');
    event.initEvent('refresh_autonumeric', true, true);
    document.dispatchEvent(event);
  }

  renderSuccess(e) {
    const el = e.currentTarget
    const [data, status, xhr] = e.detail
    if (!data.body) return null
    if (el.dataset.placement) {
      const df = this._documentFragment(e)
      if (el.dataset.placement == 'append') this._append(el, df)
      else if (el.dataset.placement == 'prepend') this._prepend(el, df)
      else if (el.dataset.placement == 'replace') this._replace(el, df)
      else if (el.dataset.placement == 'replace_inner') this._replaceInner(el, e)
      this.removeModal();
    }
    if (el.dataset.reset == 'true') el.reset()
    this._removeValidations(el)
    BSN.initCallback(this._target(el))
  }

  toggleField(e) {
    let el = e.currentTarget
    let fields = el.dataset.field.split(',')
    for (const f of fields) {
      let field = document.querySelector(f)
      let container = field.parentElement.parentElement
      if (el.checked) container.classList.remove('hide')
      else container.classList.add('hide')
    }
  }

  removeModal() {
    let modal = document.querySelector('.modal')
    if (modal) {
      let instance = new Modal(modal)
      instance.hide();
      modal.remove();
    }
  }

  // Private methods

  _append(element, fragment) {
    this._target(element).append(fragment)
  }

  _prepend(element, fragment) {
    this._target(element).prepend(fragment)
  }

  _replace(element, fragment) {
    this._target(element).replaceWith(fragment)
  }

  _replaceInner(el, e) {
    const element = this._target(el)
    const popoverToggle = element.querySelector('[data-toggle="popover"]')
    if (popoverToggle) {
      const popoverInit = popoverToggle.Popover
      popoverInit.hide();
    }
    const [data, status, xhr] = e.detail
    element.innerHTML = xhr.responseText
    this._hideSpinner(el)
    BSN.initCallback(element);
  }

  _documentFragment(e) {
    const [data, status, xhr] = e.detail
    const df = document.createDocumentFragment()
    for (const node of data.body.children) df.appendChild(node)
    return df
  }

  _target(el) {
    return document.querySelector(el.dataset.responseTarget)
  }

  _removeValidations(element) {
    let feedbacks = element.querySelector('.invalid-feedback')
    if (feedbacks)
      feedbacks.remove();

    let invalids = element.querySelector('.is-invalid')
    if (invalids)
      invalids.classList.remove('is-invalid');
  }

  _hideSpinner(element) {
    let targetEl = this._target(element)
    let spinnerEl = targetEl.querySelector('[data-target="time-entry.spinner"]')
    if (spinnerEl)
      spinnerEl.classList.add('hide');
  }
}
