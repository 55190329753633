import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tabs']

  connect() {
    this.init()
    let event = new Event('click', { bubbles: true })
    // this.activeTab.dispatchEvent(event)
  }

  init() {
    for (const tab of this.tabsTarget.querySelectorAll('[data-toggle="remote-tab"]')) {
      let loadHandler = this.load.bind(this)
      let ajaxBeforeHandler = this.start.bind(this)
      let ajaxSuccessHandler = this.render.bind(this)
      let ajaxCompleteHandler = this.complete.bind(this)

      tab.addEventListener('click', loadHandler)
      tab.addEventListener('ajax:before', ajaxBeforeHandler)
      tab.addEventListener('ajax:success', ajaxSuccessHandler)
      tab.addEventListener('ajax:complete', ajaxCompleteHandler)
    }
  }

  load(e) {
    this.activeTab = e.currentTarget
  }

  start(e) {
    this.target(e.currentTarget).classList.add('loading')
  }

  render(e) {
    let [data, status, xhr] = e.detail
    this.target(e.currentTarget).querySelector('.content').innerHTML = data.body.innerHTML
  }

  complete(e) {
    this.target(e.currentTarget).classList.remove('loading')
  }

  target(tab) {
    return document.querySelector(tab.getAttribute('data-target'))
  }

  get activeTab() {
    return this.tabsTarget.querySelector('.active')
  }

  set activeTab(tab) {
    this.target(this.activeTab).classList.remove('active')
    this.activeTab.classList.remove('active')

    tab.classList.add('active')
    this.target(tab).classList.add('active')
    $('#scope').val(tab.dataset.tab)
  }
}
