/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../scss/application'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()


import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Legacy

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

require('trix')
require('@rails/actiontext')

import 'javascripts/components/trix'
import 'javascripts/bootstrap_native'
import '../utils/fontawesome'
