import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['suitesContainer']

  loadSuites(e) {
    const [data, status, xhr] = e.detail
    this.suitesContainerTarget.innerHTML = xhr.responseText
  }
}
