import { Controller } from 'stimulus'
import $ from 'jquery'
import moment from 'moment'
import 'moment/locale/en-gb'
import DateRangePicker from 'daterangepicker'

export default class extends Controller {
  static targets = ['datePicker']

  connect() {
    this.initializePicker()
  }

  initializePicker() {
    let start = moment(this.datePickerTarget.dataset.from)
    let end = moment(this.datePickerTarget.dataset.to)
    let direction = this.datePickerTarget.dataset.direction || 'right'
    let range

    if (this.datePickerTarget.dataset.future) {
      range = this.futureRange()
    } else {
      range = this.pastRange()
    }

    // eslint-disable-next-line no-unused-vars
    const dRangePicker = new DateRangePicker(this.datePickerTarget, {
      startDate: start,
      endDate: end,
      ranges: range,
      opens: direction,
      maxSpan: {
        'years': 5
      }
    }, this.callBack.bind(this))

    $(this.datePickerTarget).find('span.picker-content')
      .html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
  }

  callBack(start, end) {
    const formTarget = this.datePickerTarget.dataset.formTarget
    const formEl = document.querySelector('[data-target="' + formTarget + '"]')
    console.log($(this.datePickerTarget).closest('form'))
    $(this.datePickerTarget).find('span.picker-content')
      .html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
    $(formEl).find('#from').val(start.format('YYYY-MM-DD'))
    $(formEl).find('#to').val(end.format('YYYY-MM-DD'))
    $(formEl).submit()
  }

  futureRange() {
    let range = {
      'Tomorrow': [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').endOf('day')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Next Week': [moment().add(7, 'days').startOf('week'), moment().add(7, 'days').endOf('week')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
    }
    return range
  }

  pastRange() {
    let range = {
      'Today': [moment(), moment()],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(6, 'days').startOf('week'), moment().subtract(6, 'days').endOf('week')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
    return range
  }
}
