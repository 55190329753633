import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['space']

  connect() {
    let free = document.getElementById('enquiry_freehold')
    let lease = document.getElementById('enquiry_leasehold')
    if (free.checked || lease.checked) {
      this.spaceTarget.hidden = false
    } else {
      this.spaceTarget.hidden = true
    }
  }

  hideSqft() {
    let free = document.getElementById('enquiry_freehold')
    let lease = document.getElementById('enquiry_leasehold')
    if (free.checked || lease.checked) {
      this.spaceTarget.hidden = false
    } else {
      this.spaceTarget.hidden = true
      let fromEl = document.getElementById('enquiry_space_from')
      let toEl = document.getElementById('enquiry_space_to')
      fromEl.value = null
      toEl.value = null
    }
  }
}